/* Open the sidenav */
function openNav() {
  document.getElementById("main-nav").style.width = "100%";
}

/* Close/hide the sidenav */
function closeNav() {
  document.getElementById("main-nav").style.width = "";
} 

/* Header verkleinern/-größern */
function reduceHeader() {
	$('#header').removeClass('py-md-3');
	$('#serviceNavi-top > ul').css('margin-bottom','0');
	$('#loginBtnTop').css('padding-top','0.075rem').css('padding-bottom','0.075rem');
	$('.breadcrumb-container').css('line-height','30px');
	$('.navbar .megamenu').css('top','35px');
}

function enhanceHeader() {
	$('#header').addClass('py-md-3');
	$('#serviceNavi-top > ul').css('margin-bottom','1rem');
	$('#loginBtnTop').css('padding-top','0.375rem').css('padding-bottom','0.375rem');
	$('.breadcrumb-container').css('line-height','40px');
	$('.navbar .megamenu').css('top','50px');
}

// Open the full screen search box
function openSearch() {
  $('#searchOverlay').fadeIn();
  $('#searchInput').focus();
}

// Close the full screen search box
function closeSearch() {
  $('#searchOverlay').fadeOut();
} 

$('#searchInput').on('focusout', function () {
  closeSearch();
});

$(document).keyup(function(e) {
  if (e.keyCode == 27) { closeSearch(); }   // esc
});


$(document).ready(function(){
	$(window).scroll(function () {
			if ($(this).scrollTop() > 50) {
				$('#back-to-top').fadeIn();
				reduceHeader();
			} else {
				$('#back-to-top').fadeOut();
				enhanceHeader();
			}
		});
		// scroll body to 0px on click
		$('#back-to-top').click(function () {
			$('body,html').animate({
				scrollTop: 0
			}, 400);
			return false;
		});
});

$(document.links).filter(function() {
  return this.hostname != window.location.hostname;
}).attr('target', '_blank');

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})


// Chosen Selects
if($(".chosen-select").length > 0) {
	$(".chosen-select").chosen();
}